@import '../../styles/variables.scss';

.card-img-wr img {
  object-fit: cover;
  height: 200px;
}

.card-text {
  line-height: 1;
}

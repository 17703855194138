@import '../../styles/variables.scss';

.navbar-light .navbar-nav .nav-link {
  color: $grey;
  transition: all 150ms ease;
  &.active,
  &:hover,
  &:focus {
    color: $primary-color;
  }
}

@import '../../styles/variables.scss';

.privacy-policy-modal {
	.modal-content {
		.modal-body {
			text-align: left;
		}
		
		.modal-title {
			font-size: 2.7rem !important;
			line-height: 3.1rem !important;
		}
		
		span, div {
			font-size: 1.3rem !important;
		}
		
		h1 {
			font-size: 2.5rem !important;
			
			span {
				font-size: 2.5rem !important;;
			}
		}
		
		h2 {
			font-size: 2rem !important;;
			
			span {
				font-size: 2rem !important;;
			}
		}
		
		@media screen and (max-width: 600px) {
			.modal-title {
				font-size: 2.5rem !important;
				line-height: 2.7rem !important;
			}
			
			span, div {
				font-size: 1.1rem !important;
			}
			
			h1 {
				font-size: 2rem !important;
				
				span {
					font-size: 2rem !important;;
				}
			}
			
			h2 {
				font-size: 1.7rem !important;;
				
				span {
					font-size: 1.7rem !important;;
				}
			}
		}
		
		@media screen and (max-width: 360px) {
			.modal-title {
				font-size: 2.2rem !important;
				line-height: 2.3rem !important;
			}
			
			span, div {
				font-size: 1rem !important;
			}
			
			h1 {
				font-size: 1.6rem !important;
				
				span {
					font-size: 1.6rem !important;;
				}
			}
			
			h2 {
				font-size: 1.3rem !important;;
				
				span {
					font-size: 1.3rem !important;;
				}
			}
		}
		
		@media screen and (max-width: 310px) {
			.modal-title {
				font-size: 2rem !important;
				line-height: 2rem !important;
			}
			
			span, div {
				font-size: 0.8rem !important;
			}
			
			h1 {
				font-size: 1.3rem !important;
				
				span {
					font-size: 1.3rem !important;;
				}
			}
			
			h2 {
				font-size: 1rem !important;;
				
				span {
					font-size: 1rem !important;;
				}
			}
		}
		
		@media screen and (max-width: 290px) {
			.modal-title {
				font-size: 1.6rem !important;
				line-height: 1.6rem !important;
			}
			
			span, div {
				font-size: 0.6rem !important;
			}
			
			h1 {
				font-size: 1.1rem !important;
				
				span {
					font-size: 1.1rem !important;;
				}
			}
			
			h2 {
				font-size: 0.9rem !important;;
				
				span {
					font-size: 0.9rem !important;;
				}
			}
		}
		
		@media screen and (max-width: 240px) {
			.modal-title {
				font-size: 1.3rem !important;
				line-height: 1.3rem !important;
			}
			
			span, div {
				font-size: 0.4rem !important;
			}
			
			h1 {
				font-size: 0.8rem !important;
				
				span {
					font-size: 0.8rem !important;;
				}
			}
			
			h2 {
				font-size: 0.7rem !important;;
				
				span {
					font-size: 0.7rem !important;;
				}
			}
		}
		
		@media screen and (max-width: 200px) {
			.modal-title {
				font-size: 1rem !important;
				line-height: 1rem !important;
			}
			
			span, div {
				font-size: 0.3rem !important;
			}
			
			h1 {
				font-size: 0.7rem !important;
				
				span {
					font-size: 0.7rem !important;
				}
			}
			
			h2 {
				font-size: 0.5rem !important;;
				
				span {
					font-size: 0.5rem !important;;
				}
			}
		}
	}
}

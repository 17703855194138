@import '../../styles/variables.scss';

.activity-image img{
  max-height: 445px;
  object-fit: cover;
  max-width: 100%;
}

.sidebar-item {
  min-height: 240px;
  ul {
    list-style-type: none;
  }
}

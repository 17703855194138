.settings-list {
  border: none;
}

.settings-list a {
  margin-top: 10px;
  border-radius: 0 !important;
}

.settings-list a svg {
  color: black;
}

@import '../../../styles/variables.scss';

.login-background {
    background-color: $grey;
    min-height: 100vh;
}

.sent-pass-box {
    max-width: 370px;
}

.login-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .login-form-container form {
    max-width: 260px;
  }
  
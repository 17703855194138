@import "../../../styles/variables.scss";

.profile-image {
  max-width: 580px;
}

.user-name-wr {
  color: $grey;
  svg {
    font-size: 40px;
  }
}
.user-name {
  font-size: 36px;
  line-height: 0.8;
}
.profile-info-wr {
  word-break: break-all;
  a {
    color: $grey;
    text-decoration: underline;
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}

.info-btn {
  min-width: 205px;
}

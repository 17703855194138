@import '../../styles/variables.scss';

.list-group {
  border: 1px solid $grey;
  border-top: 0
}
.list-group-item {
  border-right: none;
  border-left: none;
  border-radius: 0;
  cursor: pointer;
}

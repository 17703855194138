@import '../../../styles/variables.scss';

.activity-group-list {
  border: none;
  border-radius: 0;
  .list-group-item {
    border-radius: 0;
    background-color: rgb(233, 233, 233);
    button {
      background-color: white;
    }
    button:hover {
      background-color: black;
      color: white;
    }
  }
  p {
    margin-bottom: 0;
  }
};
@import '../../styles/variables.scss';

#react-autowhatever-1 {
  top: 100%;
  position: absolute;
  width: 100%;
  z-index: 1;
}
.autosuggest {
  position: relative;
}

.content {
  box-sizing: border-box;
  max-width: 100%;
  margin-bottom: 20px;
}

.pell-actionbar {
  border: 1px solid black;
}

.pell-content {
  height: 300px;
  border: 1px solid black;
  border-top: 0;
  outline: 0;
  padding: 5px;
  overflow: scroll;
  
  
  
  h1 {
    font-size: 2.5rem !important;
    
    span {
      font-size: 2.5rem !important;;
    }
  }
  
  h2 {
    font-size: 2rem !important;;
  
    span {
      font-size: 2rem !important;;
    }
  }
  
  pre {
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
  }
}

#html-output {
  white-space: pre-wrap;
}

@import '../../../styles/variables.scss';

.team-name {
  color: $grey-200;
  font-size: 16px;
  font-weight: 700;
}

.color-label {
  width: 21px;
  height: 21px;
}

@import './variables.scss';

$theme-colors: (
        primary: $primary-color,
        red: $red,
        secondary: $grey,
) !default;

// Bootstrap library
@import './node_modules/bootstrap/scss/bootstrap';

body {
    color: $grey;
}

h1 {
    font-weight: 900;
    font-size: 36px;
}

h2 {
    font-weight: 900;
    font-size: 36px;
}

p {
    font-size: 20px;
    font-weight: 300;
    word-wrap: break-word;
}

textarea {
    resize: none;
}

.form-control {
    background-color: #ffffff;
    box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.22);
    border: 1px solid $grey;
    border-radius: 0;
    font-weight: 500;
    font-size: 18px;
    height: 46px;
    &:focus {
        box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.42);
        border-color: $grey;
    }
}

textarea.form-control {
    height: auto;
}

.t-underline {
    text-decoration: underline;
}

.btn {
    height: 46px;
    padding: 0.2rem 1rem;
}

.big-btn {
    min-width: 290px;
}

.btn-outline-secondary:hover *{
  color: #fff;
}

.fz-18 {
    font-size: 18px;
}

.fz-14 {
    font-size: 14px;
}

.fz-24 {
    font-size: 24px;
}

.fz-32 {
    font-size: 32px;
}

.fz-40 {
    font-size: 40px;
}

.fw-300 {
    font-weight: 300;
}

.fw-500 {
    font-weight: 500;
}

.fw-700 {
    font-weight: 700;
}

.fw-900 {
    font-weight: 900;
}

.ml-20 {
    margin-left: 20px;
}

.default-box-shadow {
    box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.22);
}

.word-wrap-brake {
    word-wrap: break-word;
}

.cursor-pointer{cursor: pointer;}

.modal-header {
    h5 {
        font-size: 36px;
        font-weight: 900;
    }
    .close {
        font-size: 30px;
    }
}

.calendar-field {
    input {
        border: 1px solid $grey;
        height: 46px;
        color: $grey;
        font-size: 18px;
        font-weight: 500;
        padding-left: 40px;
        text-transform: capitalize;
        padding-bottom: 3px;
        width: 185px;
    }
    button {
        left: 7px;
        background: transparent;
        border: none;
        top: 0;
        bottom: 0;
        margin: auto;
        color: $grey;
        svg {
            font-size: 18px;
        }
    }
}

.info-btns {
    > div {
        margin-right: -1px;
    }
}

.input-customize {
    position: relative;
    margin-bottom: 0;
    svg {
        position: absolute;
        top: 0;
        bottom: 0;
        right: auto;
        margin: auto;
        left: 15px;
        font-size: 20px;
    }
    input {
        padding-left: 40px;
    }
}

.select-customize {
    position: relative;
    margin-bottom: 0;
    &.min-w-100 {
        select {
            min-width: 100%;
        }
    }
    select {
        position: relative;
        padding: 0 30px 0 17px;
        border: 1px solid $grey;
        background: transparent;
        height: 46px !important;
        color: $grey;
        font-size: 18px;
        font-weight: 500;
        text-transform: capitalize;
        min-width: 268px;
        border-radius: 0;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        z-index: 1;
        cursor: pointer;
        &:not([disabled]):focus,
        &:not([disabled]):hover {
            border: 1px solid $grey;
            box-shadow: none;
            background: transparent;
        }
        &:disabled {
            cursor: inherit;
        }
    }
    svg {
        position: absolute;
        right: 10px;
        bottom: 0;
        top: 0;
        margin: auto;
        font-size: 24px;
        color: $grey;
    }
}

.spinner-wrapper {
    position: relative;
    padding: 0 35px;
}

.list-group {
    background: #fff;
}

.separator {
    background: $light-grey;
    border: none;
    height: 2px;
}

.files-list {
    list-style-type: none;
    min-width: 200px;
    li {
        margin-bottom: 10px;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
    a {
        color: $grey;
        text-decoration: underline;
        font-weight: 500;
        font-size: 18px;
        &:hover,
        &:focus {
            text-decoration: none;
        }
    }
}

.placeholder {
    background: $light-grey;
}

#react-autowhatever-1 {
    z-index: 11 !important;
}
.color-label {
    width: 21px;
    height: 21px;
    &.big {
        width: 28px;
        height: 28px;
    }
}

.grey-bg {
    background: $grey;
    color: #fff;
}
.green-bg {
    background: $primary-color;
    color: #fff;
}

.color-label {
    width: 21px;
    height: 21px;
}

.grey-bg {
    background: $grey;
    color: #fff;
}
.green-bg {
    background: $primary-color;
    color: #fff;
}

.disabled-icon {
   color: $grey4;
}

@media (max-width: 992px) {
    .info-btns {
        > div {
            width: 50%;
            margin-bottom: 10px;
            input {
                width: 100%;
                border: 1px solid $grey;
            }
        }
    }
    .select-customize {
        select {
            min-width: auto;
        }
    }
}

.separator {
    background: $light-grey;
    border: none;
    height: 2px;
}

.files-list {
    list-style-type: none;
    min-width: 200px;
    li {
        margin-bottom: 10px;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
    a {
        color: $grey;
        text-decoration: underline;
        font-weight: 500;
        font-size: 18px;
        &:hover,
        &:focus {
            text-decoration: none;
        }
    }
}

@media (min-width: 992px) {
    .modal-lg.modal-dialog {
        max-width: 800px;
    }
    .modal-fs.modal-dialog {
        max-width: 1000px;
    }
}

@media (max-width: 575px) {
    .info-btns {
        > div {
            width: 100%;
        }
    }
}

@media (max-width: 767px) {
    .btn-xs-block {
        display: block;
        width: 100%;
    }
    input[type="submit"].btn-xs-block,
    input[type="reset"].btn-xs-block,
    input[type="button"].btn-xs-block {
        width: 100%;
    }
    .btn-block + .btn-xs-block,
    .btn-xs-block + .btn-block,
    .btn-xs-block + .btn-xs-block {
        margin-top: 0.5rem;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .btn-sm-block {
        display: block;
        width: 100%;
    }
    input[type="submit"].btn-sm-block,
    input[type="reset"].btn-sm-block,
    input[type="button"].btn-sm-block {
        width: 100%;
    }
    .btn-block + .btn-sm-block,
    .btn-sm-block + .btn-block,
    .btn-sm-block + .btn-sm-block {
        margin-top: 0.5rem;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .btn-md-block {
        display: block;
        width: 100%;
    }
    input[type="submit"].btn-md-block,
    input[type="reset"].btn-md-block,
    input[type="button"].btn-md-block {
        width: 100%;
    }
    .btn-block + .btn-md-block,
    .btn-md-block + .btn-block,
    .btn-md-block + .btn-md-block {
        margin-top: 0.5rem;
    }
}
@media (min-width: 1200px) {
    .btn-lg-block {
        display: block;
        width: 100%;
    }
    input[type="submit"].btn-lg-block,
    input[type="reset"].btn-lg-block,
    input[type="button"].btn-lg-block {
        width: 100%;
    }
    .btn-block + .btn-lg-block,
    .btn-lg-block + .btn-block,
    .btn-lg-block + .btn-lg-block {
        margin-top: 0.5rem;
    }
}
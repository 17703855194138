@import '../../styles/variables.scss';

.note-row {
  border-top: 1px solid $grey-200;
  transition: all 100ms ease;
  &:hover {
    background: $grey-100;
  }
}

.desc-row {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

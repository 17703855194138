@import '../../styles/variables.scss';

.modal-dialog {
  max-width: 600px;
}

.custom-input input,
.custom-input .checked {
  display: none;
}

.custom-input input:checked ~ .checked
{
  display: inline-block;
}

.custom-input input:checked ~ .unchecked
{
  display: none;
}

.profile-image-edit {
  width: 290px;
  height: 250px;
  object-fit: cover;
}
